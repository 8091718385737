<template>
    <div class="relative w-full">
        <Listbox as="div" v-model="selected">
            <ListboxLabel class="sr-only">Select Skill</ListboxLabel>
            <div class="relative">
                <ListboxButton class="flex justify-between w-full divide-x divide-indigo-700 rounded-md shadow-sm">
                    <div
                        class="flex flex-col w-full items-start gap-x-1.5 rounded-l-md bg-white px-3 py-3 border border-gray-300">
                        <!--                        <CheckIcon class="-ml-0.5 h-5 w-5" aria-hidden="true"/>-->
                        <p class="text-black font-bold">
                            {{ selected.label }}
                        </p>
                    </div>

                    <div
                        class="items-center rounded-l-none rounded-r-md bg-gray-600 self-stretch px-3 justify-center flex hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                        <span class="sr-only">Change published status</span>
                        <ChevronDownIcon class="size-5 text-white" aria-hidden="true"/>
                    </div>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                    <ListboxOptions
                        class="absolute left-0 z-10 mt-2 w-[600px] origin-top-left divide-y divide-gray-200 overflow-hidden rounded-md bg-white  ring-2 ring-black ring-opacity-5 focus:outline-none shadow-2xl">
                        <ListboxOption as="template" v-for="option in options" :key="option.title"
                                       :value="option" v-slot="{ active, selected }">
                            <li :class="[(active || selected) ? 'bg-gray-800 text-white' : 'text-gray-900 bg-white', 'cursor-pointer select-none p-4 text-sm']">
                                <div class="flex gap-3 items-center">

                                    <div class="flex flex-col">
                                        <div class="flex gap-2">
                                            <p :class="selected ? 'font-semibold' : 'font-normal'">{{
                                                    option.label
                                                }}</p>
                                            <span v-if="selected" :class="active ? 'text-white' : 'text-white'">
                                                     <CheckIcon class="h-5 w-5" aria-hidden="true"/>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>

<script setup>
import {ref, watch} from 'vue'
import {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions} from '@headlessui/vue'
import {
    ArrowRightStartOnRectangleIcon,
    CheckIcon,
    ChevronDownIcon, DevicePhoneMobileIcon,
    EnvelopeIcon, GlobeAmericasIcon,
    UserIcon, LinkIcon
} from '@heroicons/vue/20/solid'
import useSkillTypes from "@/Composeables/useSkillTypes.js";

const options = [
    {value: 'en', label: 'English'},
    {value: 'es', label: 'Spanish (Coming Soon)'},
    {value: 'fr', label: 'French (Coming Soon)'},
    {value: 'de', label: 'German (Coming Soon)'},
    {value: 'it', label: 'Italian (Coming Soon)'},
    {value: 'pt', label: 'Portuguese (Coming Soon)'},
];

const model = defineModel();

const selected = ref(options.find(option => option.value === model.value) ?? options[0])

watch(() => selected.value, (value) => {
    model.value = value.value
}, {immediate: true})

</script>
