<template>
    <div class="w-full bg-gray-200/40 rounded-lg overflow-hidden p-0.5"
         :class="{
        'flex items-center justify-center relative': loading
    }"
    >
        <CircularLoader v-if="loading"
                        class="w-full h-12 absolute"/>

        <div ref="container" class="cursor-pointer"></div>
    </div>
</template>
<script setup>
import {ref, onMounted, watch} from "vue";
import WaveSurfer from "wavesurfer.js";
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js'
import CircularLoader from "@/Components/CircularLoader.vue";

const props = defineProps(['audio'])

const wavesurfer = ref()
const container = ref()
const hidePlayButton = ref(false)
const loading = ref(false)
let loadingPromise;
const playPause = () => {
    hidePlayButton.value = !hidePlayButton.value
    wavesurfer.value.playPause()
}

defineExpose({
    playPause,
    loading
})

const emits = defineEmits(['play', 'pause'])

onMounted(() => {
    wavesurfer.value = WaveSurfer.create({
        container: container.value,
        waveColor: 'rgb(0, 0, 0)',
        progressColor: 'rgb(0, 0, 255)',
        // url: props.audio,
        interact: true,
        height: 45,
        plugins: [
            Hover.create({
                lineColor: '#ff0000',
                lineWidth: 2,
                labelBackground: '#555',
                labelColor: '#fff',
                labelSize: '11px',
            }),
        ],

        /**
         * Render a waveform as a squiggly line
         * @see https://css-tricks.com/making-an-audio-waveform-visualizer-with-vanilla-javascript/
         */
        renderFunction: (channels, ctx) => {
            const {width, height} = ctx.canvas
            const scale = channels[0].length / width
            const step = 5

            ctx.translate(0, height / 2)
            ctx.strokeStyle = ctx.fillStyle
            ctx.beginPath()

            for (let i = 0; i < width; i += step * 2) {
                const index = Math.floor(i * scale)
                const value = Math.abs(channels[0][index])
                let x = i
                let y = value * height + 4

                ctx.moveTo(x, 0)
                ctx.lineTo(x, y)
                ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, true)
                ctx.lineTo(x + step, 0)

                x = x + step
                y = -y
                ctx.moveTo(x, 0)
                ctx.lineTo(x, y)
                ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, false)
                ctx.lineTo(x + step, 0)
            }

            ctx.stroke()
            ctx.closePath()
        },
    })

    wavesurfer.value.on('interaction', (e) => {
        wavesurfer.value.play()
        emits('play')
    })

    /** When the audio starts playing */
    wavesurfer.value.on('play', () => {
        hidePlayButton.value = true
        emits('play')
    })

    /** When the audio pauses */
    wavesurfer.value.on('pause', () => {
        hidePlayButton.value = false
        emits('pause')
    })

    watch(() => props.audio, async () => {
        loading.value = true
        loadingPromise = new Promise(async resolve => {
            await wavesurfer.value.load(props.audio)
            loading.value = false
        })
    }, {immediate: true})
})

</script>
