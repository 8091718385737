<template>
    <div
        class="rounded-lg relative flex flex-col items-center justify-end">

        <div class="absolute z-0 inset-0 w-full h-full rounded-lg overflow-hidden">
            <img :src="src" alt="" class="w-full h-full object-cover object-center"/>
        </div>

        <div
            class="absolute inset-0 w-full h-full bg-gradient-to-t from-transparent to-slate-900 rounded-lg overflow-hidden"></div>

        <div class="absolute z-10 right-6 top-6 text-white">
            <slot name="title"/>
        </div>

        <div class="relative z-10 pb-4 flex-col gap-4 items-center w-full px-6">
            <FaceRecordingAudioWavesurfer
                v-if="mounted"
                :key="file"
                :audio="file"
                @play="hidePlayButton = true"
                @pause="hidePlayButton = false"
                class=""
                ref="player"/>

            <div class="flex gap-4 mt-4">
                <LanguageDropdown v-model="form.language" class="w-full"/>

                <button
                    :disabled="player?.loading"
                    @click="playPause"
                    :class="{
                        '': hidePlayButton,
                        'opacity-25': player?.loading
                    }"
                    class="cursor-pointer">

                    <span
                        class="size-12 text-xs flex items-center gap-2 text-primary bg-white rounded-full p-2 border-2 hover:border-black">
                        <PlaySvg v-if="!hidePlayButton"/>
                        <PauseSvg v-else/>
                    </span>
                </button>
            </div>
        </div>

    </div>
</template>
<script setup>
import {ref, onMounted, computed, watch} from "vue";
import PlaySvg from "@/Components/PlaySvg.vue";
import PauseSvg from "@/Components/PauseSvg.vue";
import {useForm} from "@inertiajs/vue3";
import LanguageDropdown from "@/Components/Marketing/FacePlayer/LanguageDropdown.vue";
import FaceRecordingAudioWavesurfer from "@/Components/Marketing/FacePlayer/FaceRecordingAudioWavesurfer.vue";

const props = defineProps(['src', 'variant'])

const form = useForm({
    language: 'en',
    variant: props.variant
});

const file = computed(() => route('audio-example.get', {language: form.language, variant: form.variant}));
const player = ref()

watch(file, async () => {
    await player.value.loadingPromise;
    playPause()

})

const mounted = ref(false)
const hidePlayButton = ref(false)

const playPause = () => {
    player.value.playPause()

    // hidePlayButton.value = !hidePlayButton.value
}

onMounted(() => {
    mounted.value = true
})
</script>
